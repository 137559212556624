.tagImg {
    height: 30px;
    width: 30px;
}

.iImg {
    height: 15px;
    width: 15px;
}

.custom-loader {
    position: relative !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

.popup-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 1201;
    background: #a2a2a28f;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}
.container-main {
    width: 950px;
    background: #EFEFEF;
}


.travelButton {
    border: 1px solid #EFEFEF;
    border-radius: 25px;
    margin: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mainContainer::-webkit-scrollbar {
    display: none;
}

.mainContainer {
    overflow-x: scroll;
}

.date-position {
    position: relative;
    bottom: 28px;
}

.expenseInsideBox {
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    gap: 15px;
    align-items: baseline;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.w-100 {
    width: 100%;
}