.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pagination {
  display: flex;
  padding: 10px 0;
  gap: 10px;
}

.page-item a:hover {
  /* border: 2px solid 027DC2; */
  /* font-size: 15px;
  font-weight: 500; */
}
.page-item a {
  border: 1px solid #027DC2;
  min-width: 25px;
  min-height: 26px;
  padding: 0 15px;
  /* background: #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
}
.page-item .active {
  cursor: not-allowed;
  background-color: #027DC2!important;
  color: #fff!important;

}


.show-entries-d select {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #000;
  outline: none;
}
.show-entries-d {
  justify-content: end;
    align-items: baseline;
    gap: 5px;
    font-size: 14px;
}
.page-item a {
  color: #027DC2!important;
  font-size: 13px;
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-link {
  text-decoration: none;
  color: inherit;
  /* Use the default text color */
}

.nav-link:hover {
  text-decoration: underline;
  /* Add underline on hover if desired */
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: none;
}

.scale-effect-with-shadow {
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  transition: all ease 200ms;
}

.scale-effect-with-shadow:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.scale-effect {
  overflow: hidden;
  cursor: pointer;
  transition: all ease 200ms;
}

.scale-effect:hover {
  transform: scale(1.03);
  background-color: rgb(200 214 227 / 29%);
}

.bg-Effect:hover {
  background-color: rgb(200 214 227 / 29%);
}

.smallCard {
  height: auto;
  border-radius: 10px;
  margin: 10px;
  position: relative;
}

.bar {
  width: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
}

.status-Img {
  background-color: rgb(34, 197, 94);
  border-radius: 7px;
  padding: 7px;
  height: 40px;
  width: 40px;
  margin: 10px;
}

.inner-Container {
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* .css-v4u5dn-MuiInputBase-root-MuiInput-root{
  width: 110% !important;
} */

.inputbox {
  border: 1px solid rgb(190, 189, 189);
  border-radius: 10px;
}

.bgCard {
  background-color: #eae8e8;
  border-radius: 10px;
  padding: 10px;
}

.tagContainer {
  max-height: 40vh;
  overflow-y: auto;
}

.tagCard {
  box-shadow: 0px 2px 7px 0px rgba(119, 119, 119, 0.12);
  border-radius: 10px;
}

.MuiInput-root input[type='number']::-webkit-inner-spin-button,
.MuiInput-root input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  /* min-width: 22vw !important; */
  /* width: auto !important; */
}

.travelIcon {
  height: 20px;
  object-fit: contain;
}

.crossicon {
  cursor: pointer;
}



.file-previews li {
  position: relative;
  padding: 2px 4px 2px 11px;
  background: #ccc;
  border-radius: 20px;
  display: flex;
  gap: 5px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.file-previews {
  width: 100%;
  padding: 0;
  /* display: flex; */
  gap: 10px;
  margin: 0 0 8px 0px
    /* margin-bottom: 4px; */
}

.hide-scroll-button::-webkit-scrollbar {
  display: none !important;
}

ul li {
  list-style: none;
}

.preview-list-item {
  border: 1px solid red;
}

.bulkpreviewhander {
  padding: 0px 10px;
  gap: 20px;
}

.preview-list-item ul li {
  font-size: 12px;
  font-weight: 500;
  color: #282c34;
  padding: 5px;
}

.new-text-class #input-with-icon-textfield {
  min-width: 135px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#input-with-icon-textfield {
  min-width: 200px !important
}

#input-endLoaction-textfield {
  min-width: 180px !important;
  max-height: 23px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.custom-role #demo-multiple-chip {
  padding: 1px !important;
}

.css-h4y409-MuiList-root {
  /* max-height: 60vh !important;
  overflow-y: auto; */
}

.adminLine-border {
  border-left: 1px solid #282c34;
  padding: 30px 0px;
}


.css-2m9kme-MuiFormControl-root {
  margin: 0px !important;
}

.policyVoilationPopUp {
  max-height: 70vh;
  overflow-y: auto;
}

.city-modal-style {
  background-color: rgb(239, 239, 239) !important;
  padding: 1px 0px;
}

.city-modal-style ul li {
  list-style: none;
  padding: 4px 11px;
  text-align: start;
  transition: 0.3s;
}

.city-modal-style ul li:hover {
  background-color: white;
}

.city-modal-style ul {
  margin-left: 0;
  padding-left: 0;
}

.z-1 {
  z-index: 1;
}