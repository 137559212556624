.blueButtonStyle{
    border: 2px solid transparent;
    border-radius: 25px;
    padding: 7px;
    background-color: #027DC2;
}
.customBlueButtonStyle{
    border: 2px solid transparent;
    border-radius: 25px;
    padding: 7px;
    background-color: #027DC2;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.whiteButtonStyle{
    border: 2px solid #027DC2;
    border-radius: 25px;
    padding: 7px;
}
.cancelButtonStyle{
    border: 1px solid #EF4444;
    border-radius: 25px;
    padding: 7px;
}
.functionalButtonStyle{
    border-radius: 25px;
    padding: 7px;
}
.redButtonStyle{
    border: 2px solid transparent;
    border-radius: 25px;
    padding: 7px;
    background-color: #EF4444;
}

.css-1717ajk-MuiFormLabel-root-MuiInputLabel-root{
    font-family: openSans-regular !important;
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    letter-spacing: 0px !important;
    color: #202020 !important;
}
.checkBoxText{
    font-family: openSans-regular;
    font-size: 1rem ;
    font-weight: 500 ;
    line-height: 26px ;
    letter-spacing: 0px ;
    color: #202020 ;
}
.addButton{
    display: inline-block;
    /* padding-left: 20px;
    padding-right: 20px; */
}