body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: openSans-bold;
  src: url('./assets/font/OpenSans-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: openSans-regular;
  src: url('./assets/font/OpenSans-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: openSans-light;
  src: url('./assets/font/OpenSans-Regular.ttf');
  font-weight: lighter
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #027DC2 !important;
  color: #ffff;
  border-color: #ffff;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1.350rem !important;
  color: #202020 !important;
  line-height: 1.5375em !important;
}

label+.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  margin-top: 25px !important;
}

label+.css-1rb63tl-MuiInputBase-root-MuiInput-root {
  margin-top: 25px !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-container {
  background-color: #027DC2 !important;
}

@media (min-width: 600px) and (max-width: 700px) {
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    max-width: 60vw !important;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    max-width: 60vw !important;
  }
}

@media (min-width: 901px) {
  .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    max-width: 80vw !important;
  }
}

@media (min-width: 901px) and (max-width: 1250px) {
  .inside {
    width: 77vw !important;
  }
}
@media (min-width: 1251px) {
  .inside {
    width: 80vw !important;
  }
}

/* 
@media (min-width: 600px){
  .css-1ju4e4v-MuiPaper-root-MuiAppBar-root {
    width: calc(100% - 240px) !important;
    margin-left: 240px !important;
  }
} */

.css-66dh3a-MuiInputBase-input-MuiInput-input {
  font-size: 16px !important;
}

.css-3i94e7-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #fff !important;
  border-radius: 30px !important;
  padding: 10px !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date" i]::-webkit-calendar-picker-indicator {
  background-image: url('./assets/images/calender.svg');
  position: absolute;
  left: -5px;

}

input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-year-field,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field {
  color: #666262;
  margin-left: 2px;
}

input[type="date"]:required:invalid {
  color: #f7f8f9;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
    border-bottom: 1px solid #b2b0b0 !important;
    border-radius: 0px !important;
    margin: 0px !important;
}

/* input::placeholder {
  color: white;
}
input {
  border: none;
  padding: 0 20px;
  outline: none;
  color: white;
  font-size: 22px;
} */

:root {
  font-size: 12px; /* Set the base font size to 16 pixels */
}

@media (min-width: 768px) {
  :root {
    font-size: 13px; /* Increase font size for screens wider than 768px */
  }
}

@media (min-width: 1024px) {
  :root {
    font-size: 16px; /* Further increase font size for screens wider than 1024px */
  }
}
