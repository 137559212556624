.activetab{
    background-color: #027DC2;
    color: #ffff;
}
.deActivetab{
    background-color: transparent;
    color: #18181B;
}
.tabSupport{
    padding: 10px;
    border-radius: 25px;
}
.margin-top{
    margin-top: 105px!important;
}
.bottomQuery{
    background-color: #DBEEFF;
    border-radius: 10px;
}


.popup-container .container-main {
    max-height: 550px !important;
    overflow-y: scroll !important;
    border-radius: 5px;
}

.popup-container .container-main::-webkit-scrollbar-button  , .popup-container::-webkit-scrollbar-button{
    display: none !important;
  }
  
  /* Hide scrollbar */
  .popup-container .container-main  {
    scrollbar-width: thin; /* "thin" for a thin scrollbar, "none" to hide the scrollbar */
    scrollbar-color: transparent transparent; /* adjust colors as needed */
  }

.customStyle{
border-radius: 30px;
background-color: red;
}
.queryinput{
    border-radius: 10px;
    border: none;
}
.sendBtn{
    position: relative;
    right: 35px;
}
.approve{
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
}