.noOfQuery {
    background-color: #DCE3FC;
    display: inline;
    padding: 10px;
    border-radius: 10px;
}
.query{
    background-color: #F4F4F4;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
}