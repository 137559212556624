.container {
  display: flex;
  justify-content: space-between;
}

.left-side {
  width: 50%;
  background-color: blue;
}

.right-side {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.eye-icon {
  position: absolute;
  right: 3px;
  top: 21px;
  cursor: pointer;
}
.pos-relative {
  position: relative;
}
.vector-TopRight {
  position: absolute;
  bottom: 0;
  right: -34px;
  rotate: 2deg;
  width: 15%;
  height: 40%;
}

.vector-BottomRight {
  position: absolute;
  top: 0;
  right: 0;
  rotate: 0deg;
}

.overlay-container {
  position: relative;
  width: 50%;
  height: 100vh;
  /* Adjust as needed */
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* max-height: 100%; */
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* Adjust text color */
  font-size: 24px;
  /* Adjust text size */
  font-family: Arial, sans-serif;
  /* Adjust font family */
  text-align: center;
}

.logoLogin {
  height: 7vh;
  width: 25%;
}

.button {
  width: 50%;
  border-radius: 20px;
  padding: 10px;
  border-color: transparent;
}
.BGDisableButtonColor{
  background-color: #F4F4F4;
}
.BGDActiveButtonColor{
  background-color: #027DC2;
}

.buttonclicked{
  pointer-events: none;
}


.img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-screen {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.image {
  width: 100%;
  height: auto;
}

.textOn-Img {
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.bottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
}
.topRight{
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
}

@media (max-width: 425px) {
  .login-screen {
    flex-direction: column;
    /* Reverse order on smaller screens */
  }

  .rightside {
    margin-top: 20px;
  }

  .bottomRight {
    display: none;
  }
  .topRight {
    display: none;
  }
}