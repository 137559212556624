/*    Margin    */
.m-5px {
    margin: 5px;
}

.ml-5px {
    margin-left: 5px;
}

.mr-5px {
    margin-right: 5px;
}

.m-10px {
    margin: 10px;
}

.m-15px {
    margin: 15px;
}

.m-20px {
    margin: 20px;
}

.mb-20px {
    margin-bottom: 20px;
}

.m-40px {
    margin-bottom: 40px
}

.mt-5px {
    margin-top: 5px;
}

.mt-50px {
    margin-top: 50px;
}

.mb-5px {
    margin-bottom: 5px;
}

.mt-15px {
    margin-top: 15px;
}

.mb-15px {
    margin-bottom: 15px;
}

.m-1rem {
    margin: 1rem;
}

.m-1_5rem {
    margin: 1.5rem;
}

.mb-1_5rem {
    margin-bottom: 1.5rem;
}

.ml-15px {
    margin-left: 15px;
}

.ml-25px {
    margin-left: 25px;
}

.mr-15px {
    margin-right: 15px;
}

.ml-10px {
    margin-left: 10px;
}

.ml-50px {
    margin-left: 50px;
}

.ml-20px {
    margin-left: 20px;
}

.mr-10px {
    margin-right: 10px;
}

.mr-20px {
    margin-right: 20px;
}

.mb-10px {
    margin-bottom: 10px;
}

.mt-10px {
    margin-top: 10px;
}

.mt-20px {
    margin-top: 20px;
}

.mt-30px {
    margin-top: 30px;
}

.mt-35px {
    margin-top: 35px;
}

.mt-40px {
    margin-top: 40px;
}

.mt-70px {
    margin-top: 70px;
}

.mb-30px {
    margin-bottom: 30px;
}

/* Padding */

.p-1rem {
    padding: 1rem;
}

.p-1_5rem {
    padding: 1.5rem;
}

.p-8px {
    padding: 8px;
}

.p-10px {
    padding: 10px !important;
}

.p-20px {
    padding: 20px;
}

.p-3px {
    padding: 1px;
}

.p-5px {
    padding: 5px;
}

.p-15px {
    padding: 15px;
}

.p-18px {
    padding: 18px;
}

.pb-5px {
    padding-bottom: 5px;
}

.pl-5px {
    padding-left: 5px;
}

.pr-5px {
    padding-right: 5px;
}

.pl-10px {
    padding-left: 10px;
}

.pr-10px {
    padding-right: 10px;
}

.pt-10px {
    padding-top: 10px;
}

.pl-20px {
    padding-left: 20px;
}

.pr-20px {
    padding-right: 20px;
}

.pl-30px {
    padding-left: 30px;
}

.pr-30px {
    padding-right: 30px;
}

.pl-40px {
    padding-left: 40px;
}

.pr-40px {
    padding-right: 40px;
}

.pt-30px {
    padding-top: 30px;
}

.pb-25px {
    padding-bottom: 25px;
}

.pt-20px {
    padding-top: 20px;
}

.pt-10px {
    padding-top: 10px;
}

.pb-20px {
    padding-bottom: 20px;
}

/*    Width     */
.w-95per {
    width: 95%;
}

.w-96vw {
    width: 96vw;
}

.w-90vw {
    width: 90vw;
}

.w-90per {
    width: 90%;
}

.w-100per {
    width: 100%;
}

.w-80per {
    width: 80%;
}

.w-25per {
    width: 25%;
}

.w-15per {
    width: 15%;
}

.w-50per {
    width: 50%;
}

.w-200px {
    width: 200px;
}

.w-100px {
    width: 100px;
}

.w-190px {
    width: 190px;
}

.w-40vw {
    width: 40vw;
}

/* height */
.h-5vh {
    height: 5vh !important;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

.h-30vh{
    height: 30vh;
}

ul:last-child {
    margin-top: auto; /* Push the last ul item to the bottom */
    margin-bottom: 0;
  }


/* Border */
.moduleBorder {
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    background-color: #FFFFFF;
}

.moduleBorderWithoutPadding {
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 10px;
    background-color: #FFFFFF;
}

.redModuleBorderWithoutPadding {
    border: 1px solid #EF4444;
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 10px;
    background-color: #FFF2F2;
}

.curser {
    cursor: pointer;
}

.cursor-addline {
    cursor: copy;
}

.logOutPopupStyle {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.radius {
    border-radius: 10px;
}

.border {
    border: none;
    outline: none;
}

.datepicker {
    width: 150px;
}

.overflowx {
    overflow-x: auto;
}

.dotted-border {
    border: 1.5px dotted #E3E3E3;
    border-radius: 5px;
}

.gap-20px {
    gap: 20px;
}