.shareMealImg {
    object-fit: contain;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #EFEFEF;
}

.css-1wnsr1i {
    border: none !important;
    width: 50% !important;
    padding: 0px !important;
    border-radius: 10px !important;
}

.radioButtonMealWithCustomer {
    background-color: #DCFCE7;
    display: flex;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.radioButtonMealWithTeam {
    background-color: #DBEEFF;
    display: flex;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.crossImg {
    position: absolute;
    right: 0;
}
.crossImgRelative {
    position: relative;
    right: 0;
}

.popUpContainer {
    flex-direction: row;
    background-color: #f4f4f4;
    max-height: 60vh;
    overflow-y: auto;
}

.popUpInsideLeftContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50%;
}
.raiseQueryPopup{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: 50%;
}
.popUpInsideRightContainer{
    width: 50%;
}

@media (max-width: 599px) {
    .popUpContainer {
        flex-direction: column;
    }
    .popUpInsideLeftContainer {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 5px;
        width: 90%;
        align-items: center;
    }
    .popUpInsideRightContainer{
        width: 100%;
    }
    .raiseQueryPopup{
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
        width: 90%;
    }
}