.box{
    border-style: dashed;
    border-radius: 3px;
    border-width: 1px;
    border-color: #027DC2;
    align-items: center;
}
.uploadicon{
    height:20;
    width:20;
}
.editpopUpContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 10%;
}
.uploadingBox{
    padding: 10px;
    border-radius: 8;
    width: 50%;
    border: 1px solid #A1A1AA;
}
.uploadbutton{
    background-color: #027DC2;
    width: 100%;
    height:30%;
    margin-top: 5%;
    border-radius: 10px;
    padding:3%;
    text-align: center;
}
.uploadingBox{
    width:100%;
    justify-content: space-between;

}
.upload_text{
    width: 100%;
    align-items: flex-start;
    margin: 10px;
}