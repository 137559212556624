.statusImg{
    width: 50%;
    height: auto;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
}
.statusContainer{
    border-radius: 10px;
}
.graphFilter{
    /* border: 1px solid #A1A1AA; */
    border-radius: 5px;
    padding: 5px;
    margin: 10px;
}
.graphFilter:hover ,.graphFilter.selected {
    border: 1.5px solid #A1A1AA;

 }
.moduleBorder{
    border: 1px solid #E4E4E7;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
}
.overflow-x{
    overflow-x: auto;
    display: flex;
}
.dashBoadrStausBar{
    width: 20%;
  }

  @media (max-width:500px){
    .dashBoadrStausBar{
      width: 90% !important;
    }
  }